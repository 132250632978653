import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_ADEPTS, TRAINER_ADEPTS_MANAGE, TRAINER_ADEPT_TESTS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/trainer/adeptTests/Editor';
import { TAB_TESTS } from 'Components/pages/trainer/UsersManage/component';

export default class TrainerTestsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        adeptTest: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;
        actions.single({ id: params.id });
    }

    render() {
        const { adeptTest, location, history } = this.props;       

        return (
            <StyledComponent
                className="trainer-tests-manage"
                styles={require('./styles')}
            >
                <PageContent
                    elementMode={true}
                    data={adeptTest}
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_ADEPTS.path),
                        label: 'Adepci',
                    }, {
                        to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: adeptTest && adeptTest.data && adeptTest.data.adept.id }, { tab: TAB_TESTS }),
                        label: 'Testy',
                    }, {
                        to: withVariables(TRAINER_ADEPT_TESTS_MANAGE.path, { id: adeptTest && adeptTest.data && adeptTest.data.id }, {}),
                        label: `Zarządzaj testem adepta: ${(adeptTest && adeptTest.data && adeptTest.data && getFullName(adeptTest.data.adept, 'nameSurname').label)}`,
                    }]}
                >
                    {adeptTest && adeptTest.data && (
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Zarządzaj testem"
                            subHeadline={adeptTest.data.name}
                            tabs={[{
                                key: 'test',
                                label: 'Test',
                                children: (
                                    <Editor
                                        location={location}
                                        history={history}
                                        data={adeptTest.data}
                                    />
                                ),
                            }]}
                        />
                    )}
                </PageContent>
            </StyledComponent>
        );
    }
}